<template>
  <div>
    <Table
      v-loading="contactsLoading"
      custom-class="contacts-table"
      :data="tableData"
      :columns="columns"
      show-index
      rounded
      border
      :hover="false"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { Table } from '@/modules/core';
import { CONTACT_TYPE } from '@/modules/contactSet/components/contactSetInfo.vue';

const TABLE_HEADERS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  WORKS_AT: 'worksAt',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE: 'phone',
  ACTIONS: 'actions',
  ROLE: 'role',
};

export default {
  name: 'ContactsTable',
  components: { Table },
  props: {
    contacts: { type: Array, default: () => [] },
    contactsLoading: { type: Boolean, default: false },
  },
  setup() {
    return {
      activeRowActionsIndex: ref(-1),
      pickedContact: ref(null),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.FIRST_NAME}`),
          key: TABLE_HEADERS.FIRST_NAME,
          width: '8rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.LAST_NAME}`),
          key: TABLE_HEADERS.LAST_NAME,
          width: '8rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.WORKS_AT}`),
          key: TABLE_HEADERS.WORKS_AT,
          width: '12.5rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.ROLE}`),
          key: TABLE_HEADERS.ROLE,
          width: '10rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.GENDER}`),
          key: TABLE_HEADERS.GENDER,
          width: '6rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.EMAIL}`),
          key: TABLE_HEADERS.EMAIL,
          width: '16rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.PHONE}`),
          key: TABLE_HEADERS.PHONE,
          width: '9rem',
        },
      ];
    },
    tableData() {
      return this.contacts.map((contact) => {
        const { firstName, lastName, officeName, worksAtBusiness, gender, emails, phones, role, type } = contact;
        return {
          firstName: type !== CONTACT_TYPE.OFFICE ? firstName : officeName,
          lastName,
          gender: gender && this.$t(`contacts.addContactModal.${gender}`),
          worksAt: worksAtBusiness.name,
          role,
          email: emails?.[0]?.email,
          phone: phones?.[0]?.number,
        };
      });
    },
  },
  methods: {
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
  },
};
</script>
