<template>
  <div>
    <PageLayout>
      <template #header>
        <h1>{{ $t('contacts.title') }}</h1>
      </template>
      <div class="d-flex justify-content-between mb-4">
        <h3>
          {{ subtitle }}
        </h3>
      </div>
      <ContactsTable :contacts="contactsLoading ? [] : contacts" :contacts-loading="contactsLoading" />
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { computed } from 'vue';

import { useTenancy } from '@/modules/auth';
import { PageLayout } from '@/modules/core';

import { ContactsTable } from './components';
import { useContacts } from './compositions';

export default {
  components: { PageLayout, ContactsTable },
  setup() {
    const { currentTenant } = useTenancy();
    const {
      contacts,
      loading: contactsLoading,
      refetch: contactsRefetch,
    } = useContacts(computed(() => ({ businessId: currentTenant.value.id })));

    return {
      currentTenant,
      contacts,
      contactsLoading,
      contactsRefetch,
    };
  },
  computed: {
    subtitle() {
      return this.$t('contacts.subTitle', { restaurant: this.currentTenant.name });
    },
  },
};
</script>
