<template>
  <div v-loading="contactsLoading" class="card shadow p-0">
    <div class="d-flex flex-row align-items-center header">
      <div style="margin-end: 1.25rem">
        <template v-if="logo">
          <img :src="`${logo}&token=${token}`" width="44" height="44" />
        </template>
        <template v-else>
          <img src="@/assets/images/business.png" width="44" height="44" />
        </template>
      </div>
      <div>
        <h3 class="text-typography-primary mb-2">{{ contactName }}</h3>
        <div class="text-typography-primary">
          {{ $t('commons.companyNumber') }}
          {{ contactIdNumber }}
        </div>
      </div>
    </div>
    <div v-if="splittedNote.length && isAdmin" class="reconciliation-notes text-start">
      <div class="reconciliation-notes-header">{{ $t('contactCard.reconciliationNotes') }}</div>
      <div v-for="(line, index) in splittedNote" :key="index" class="reconciliation-notes-content">{{ line }}</div>
    </div>
    <hr class="divider" />
    <div v-if="contacts.length === 0" class="no-contacts d-flex align-items-center justify-content-center">
      <p class="text-typography-secondary d-flex justify-content-center">
        {{ $t('contacts.noContactsFound') }}
      </p>
    </div>
    <div v-for="(contact, index) in contacts" :key="contact.name" class="mb-2">
      <div
        class="row-card d-flex px-4 flex-row justify-content-between align-items-center"
        :class="{ ['active-actions']: activeContact === index }"
      >
        <span class="text-typography-primary">
          <div v-if="contact.type === 'office'">
            {{ contact.officeName }}
          </div>
          <div v-else>{{ contact.firstName }} {{ contact.lastName }}</div>
        </span>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <el-tooltip placement="top" :content="getTooltipContent(contact, CONTACT_DETAILS.PHONES)">
            <Button
              :disabled="!contact.phones?.length"
              type="icon"
              @click="
                contact.phones?.length < 2 && copyToClipboard({ field: 'phone', payload: contact.phones[0].number })
              "
            >
              <PhoneIcon :size="16" />
              <el-popover placement="bottom" width="174" trigger="click" popper-class="p-0 py-2 m-0">
                <Button
                  v-if="contact.phones?.length > 1"
                  slot="reference"
                  type="link"
                  class="text-typography-primary p-0"
                  @click="activeContact === null ? (activeContact = index) : (activeContact = null)"
                >
                  <ChevronDownIcon :size="12" />
                </Button>
                <div
                  v-for="phone in contact.phones"
                  :key="phone.number"
                  class="d-flex flex-row justify-content-flex-end align-items-center row-card gap-2 py-2 px-4"
                >
                  <span class="text-typography-primary w-100"> {{ phone.number }}</span>
                  <Button type="icon" class="me-1" @click="copyToClipboard({ field: 'phone', payload: phone.number })">
                    <el-tooltip :content="$t('contactCard.tooltip.copyToClipboard')" placement="top"
                      ><CopyIcon :size="16" /></el-tooltip
                  ></Button>
                </div>
              </el-popover>
            </Button>
          </el-tooltip>

          <el-divider direction="vertical" class="d-flex mx-3 align-self-center vertical-divider row-card" />
          <el-tooltip placement="top" :content="getTooltipContent(contact, CONTACT_DETAILS.EMAILS)">
            <Button
              :disabled="!contact.emails"
              type="icon"
              class="me-1"
              @click="
                contact.emails?.length < 2 && copyToClipboard({ field: 'email', payload: contact.emails[0].email })
              "
            >
              <EmailIcon :size="16" />
              <el-popover placement="bottom" trigger="click" popper-class="p-0 py-2 m-0">
                <Button
                  v-if="contact.emails?.length > 1"
                  slot="reference"
                  type="link"
                  class="text-typography-primary p-0"
                  @click="activeContact === null ? (activeContact = index) : (activeContact = null)"
                >
                  <ChevronDownIcon :size="12" />
                </Button>
                <div
                  v-for="email in contact.emails"
                  :key="email.email"
                  class="d-flex flex-row justify-content-between row-card gap-2 align-items-center py-2 px-4"
                >
                  <TruncatedText class="w-75 use text-typography-primary">{{ email.email }}</TruncatedText>
                  <div class="d-flex flex-row">
                    <div>
                      <el-tooltip :content="$t('contactCard.tooltip.copyToClipboard')" placement="top"
                        ><Button
                          type="icon"
                          class="me-1"
                          @click.stop="copyToClipboard({ field: 'email', payload: email.email })"
                        >
                          <CopyIcon :size="16" /></Button
                      ></el-tooltip>
                    </div>
                    <el-tooltip
                      v-if="isAdmin && contact.emails?.length > 1"
                      width="100%"
                      placement="top"
                      :content="contact.emails ? $t('contactCard.composeEmail') : $t('contactCard.noDetails')"
                    >
                      <Button
                        :disabled="!contact.emails"
                        type="icon"
                        class="me-1"
                        @click="openFrontCompose({ email: email.email })"
                      >
                        <ComposeIcon :size="16" />
                      </Button>
                    </el-tooltip>
                  </div>
                </div>
              </el-popover>
            </Button>
          </el-tooltip>

          <el-tooltip
            v-if="isAdmin && contact.emails?.length < 2"
            width="100%"
            placement="top"
            :content="contact.emails ? $t('contactCard.composeEmail') : $t('contactCard.noDetails')"
          >
            <Button
              :disabled="!contact.emails"
              type="icon"
              @click="openFrontCompose({ email: contact.emails[0].email })"
            >
              <ComposeIcon :size="16" />
            </Button>
          </el-tooltip>
        </div>
      </div>
      <ContactCardNote v-if="contact.note?.length && isAdmin" :note="contact.note"></ContactCardNote>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { reject, isNil } from 'ramda';

import { useReconciliationTemplate } from '@/modules/reconciliation';
import { EmailIcon, PhoneIcon, ComposeIcon, ChevronDownIcon, CopyIcon } from '@/assets/icons';
import { useUser, useTenancy } from '@/modules/auth';
import { TruncatedText } from '@/modules/core';

import { Button } from '@/modules/core/components';

import { ContactCardNote } from './components';
import { useContacts, getBusinessById } from './compositions';

const User = {
  SUPPLIER: 'supplier',
  TENANT: 'tenant',
};
const CONTACT_DETAILS = {
  EMAILS: 'emails',
  PHONES: 'phones',
};

export default {
  components: { TruncatedText, EmailIcon, PhoneIcon, ComposeIcon, Button, ContactCardNote, ChevronDownIcon, CopyIcon },
  props: {
    customer: { type: Object, default: null },
    supplier: { type: Object, default: null },
    businessId: { type: String, default: null },
    user: { type: Object, required: true },
    token: { type: String, required: true },
  },
  setup(props) {
    const { isAdmin } = useUser();
    const { currentTenant } = useTenancy();
    const { reconciliationTemplate } = useReconciliationTemplate(
      computed(() => ({
        businessId: props.businessId ?? currentTenant.value.id,
        supplierId: props.supplier.id,
      }))
    );

    const {
      contacts,
      loading: contactsLoading,
      refetch: contactsRefetch,
    } = useContacts(
      computed(() =>
        reject(isNil)({
          worksAt: props.user.payload.worksAt,
          businessId: props.businessId ?? currentTenant.value.id,
        })
      )
    );

    return {
      activeContact: ref(null),
      contacts,
      contactsLoading,
      contactsRefetch,
      reconciliationTemplate,
      copied: ref(false),
      isAdmin,
      CONTACT_DETAILS,
    };
  },
  computed: {
    splittedNote() {
      if (this.reconciliationTemplate?.note) {
        return this.reconciliationTemplate.note.split('\n');
      }
      return [];
    },
    contactName() {
      if (this.user.type === User.SUPPLIER && this.supplier) {
        return this.supplier.name;
      }
      if (this.user.type === User.TENANT && this.customer) {
        return this.customer.name;
      }
      return null;
    },
    contactIdNumber() {
      if (this.user.type === User.SUPPLIER && this.supplier) {
        return this.supplier.number;
      }
      if (this.user.type === User.TENANT && this.customer) {
        return this.customer.number;
      }
      return null;
    },
    logo() {
      if (this.user.type === User.SUPPLIER && this.supplier) {
        return this.supplier.logoUrl;
      }
      if (this.user.type === User.TENANT && this.customer) {
        return this.customer.logoUrl;
      }
      return null;
    },
  },
  methods: {
    copyToClipboard(action) {
      navigator.clipboard.writeText(action.payload);
      this.copied = true;
      if (action.field === 'phone') this.$message.success(this.$t('reconciliationModal.phoneCopied'));
      if (action.field === 'email') this.$message.success(this.$t('reconciliationModal.emailCopied'));
    },
    getTooltipContent(contact, type) {
      const items = contact[type];
      if (!items?.length) {
        return this.$t('contactCard.noDetails');
      } else if (items.length === 1) {
        return type === CONTACT_DETAILS.PHONES ? items[0].number : items[0].email;
      } else {
        return this.$t('contactCard.tooltip.totalRecords', { count: items.length });
      }
    },
    async openFrontCompose({ email }) {
      const screenDetails = await window.getScreenDetails();
      const otherScreen =
        screenDetails.screens.find((s) => s !== screenDetails.currentScreen) || screenDetails.screens[0];
      const { availTop: top, availLeft: left, availWidth: width, availHeight: height } = otherScreen;

      const getTenant = async () => {
        if (!this.customer.tenant) {
          const { tenant } = await getBusinessById(this.businessId);
          return tenant;
        }
        return this.customer.tenant;
      };

      const tenant = await getTenant();
      if (!tenant) {
        this.$message.warning(this.$t('contacts.noEmailFound'));
        return;
      }
      const from = `${tenant}@clarityo.com`;
      const frontUrl = `https://app.frontapp.com/compose?mailto=mailto:${email}?from=${from}`;
      window.open(
        frontUrl,
        '_blank',
        `left=${left},top=${top},height=${height},width=${width},popup=1,menubar=0,toolbar=0,location=0`
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.card {
  padding: 0px 0px 8px;
  width: 334px;
}

.header {
  margin-top: 1.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.row-card {
  width: 100%;
  &:hover {
    background: #f3f3f4;
  }
}

.no-contacts {
  padding: 16px;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 8px;
}

.divider {
  margin-bottom: 8px;
  border-width: 1px;
}

.vertical-divider {
  border: 1px solid #d9dcde;
  height: 16px;
}

::v-deep .tooltip-info {
  width: 20px;
}

.active-actions {
  background: #f3f3f4;
}

.reconciliation-notes {
  margin: 12px 12px 0 12px;
  padding: 12px;
  border-radius: 4px;
  word-break: normal;
  background-color: #f3f3f4;

  .reconciliation-notes-header {
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    line-height: 16px;
  }
  .reconciliation-notes-content {
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    line-height: 20px;
  }
}
</style>
