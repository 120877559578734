import { apolloClient } from '@/plugins/apollo-client';
import { gql } from '@apollo/client/core';

const BUSINESS_BY_ID = gql`
  query getBusinessById($businessId: ID!) {
    getBusinessById(businessId: $businessId) {
      tenant
    }
  }
`;

export function getBusinessById(businessId) {
  try {
    return apolloClient
      .query({
        query: BUSINESS_BY_ID,
        variables: { businessId },
      })
      .then((response) => response?.data?.getBusinessById);
  } catch (e) {
    console.error(e);
  }
}
