import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export const useContacts = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(CONTACTS_QUERY, variables, () => ({
    enabled: !!variables.value.worksAt,
  }));
  const contacts = computed(() => result?.value?.contacts?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useContacts', err);
    error();
  });

  return {
    contacts,
    loading,
    refetch,
  };
};

export const CONTACTS_QUERY = gql`
  query contacts($businessId: String, $worksAt: String) {
    contacts(businessId: $businessId, worksAt: $worksAt) {
      nodes {
        firstName
        lastName
        officeName
        type
        note
        phones {
          number
          shluha
          type
        }
        emails {
          email
        }
      }
    }
  }
`;
