<template>
  <div class="note-container">
    <div class="arrow"></div>
    <div class="content">
      {{ note }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    note: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.note-container {
  position: relative;

  .arrow,
  .content {
    background-color: #f3f3f4;
  }

  .arrow {
    position: absolute;
    padding: 6px;
    inset-inline-start: 28px;
    top: -5px;
    transform: rotate(45deg);
    border-radius: 2px;
  }

  .content {
    margin: 12px;
    padding: 12px;
    border-radius: 4px;
    word-break: normal;
    font-size: $font-size-small;
  }
}
</style>
